import { template as template_f43520bc7bfe402d83e1cb428941dfde } from "@ember/template-compiler";
const SidebarSectionMessage = template_f43520bc7bfe402d83e1cb428941dfde(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
