import { template as template_000520c5b37b41aeb1589f3b081ccbb1 } from "@ember/template-compiler";
const WelcomeHeader = template_000520c5b37b41aeb1589f3b081ccbb1(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
