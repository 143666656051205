import { template as template_8532de94aae64630b40f05adf0e00a1e } from "@ember/template-compiler";
const FKControlMenuContainer = template_8532de94aae64630b40f05adf0e00a1e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
